<template>
    <div class="find_email  position-relative">
        <SubHeader :title="title" :alert="false" class="bg-body" />
        <div class="mx-4">
            <h4 class="pt-4">
                가입하신 담당자 이름과<br>전화번호를 입력해주세요.
            </h4>
            <ul class="small my-4">
                <li class="d-flex lh-sm mb-1"><small class="text-gray-relative-500">입력한 정보를 통해 가입된 계정을 조회합니다.</small></li>
            </ul>
        </div>
        <div class="sign_box vstack gap-2 w-100 px-3 mt-5">
            <div class="form-group">
                <input required type="text" class="form-control thick bg-body text-body border-gray-relative-300" v-model="name" placeholder="담당자 이름">
            </div>
            
            <div class="form-group">
                <div class="d-flex">
                    <select class="input-group-text thick border-gray-relative-300 bg-body text-body me-1">
                        <option :value="item.value" v-for="(item,index) in service" :key="index">{{item.value}}</option>
                    </select>
                    <input class="form-control thick border-gray-relative-300 bg-body text-body" type="tel" placeholder="휴대폰 번호 '-'없이 입력" v-model="contact">
                </div>
            </div>
            <div @click="ChkInfo()" class="btn btn-main thick text-white mt-1">확인</div>
        </div>

        <!-- <div class="text-center">
            <i class="fal fa-exclamation-circle fa-3x mb-3 text-danger"></i>
            <p class="small">
                사용자 이메일 계정이<br>
                존재하지 않습니다.
            </p>
        </div>

            <router-link class="btn btn-primary w-100 mt-4 thick small" to="/signin">로그인 하기</router-link>
        <div class="text-center">
        </div> -->
    </div>
</template>

<script>
import SubHeader from '@/components/common/SubHeader.vue'
export default {
    components:{
        SubHeader
    },
    data(){
        return{
            title: '아이디 찾기',
            name: '',

            service:[
                {
                value:'SKT',
                },
                {
                value:'KT',
                },
                {
                value:'LG U+',
                },
                {
                value:'알뜰폰',
                }
            ],
        }
    },
    methods:{
        ChkInfo(){
            this.name = '홍길동';
            
            if( false ){
                this.$alert('<i class="fal fa-exclamation-circle fa-2x mb-2 text-danger"></i><p class="small mt-1">가입된 계정이<br>존재하지 않습니다.</p>')
            }else{
                this.$alert(`<i class="fal fa-check-circle fa-2x mb-2 text-success"></i><p class="small mt-1">testuser@email.com<br>로 가입된 계정이 존재합니다.</p>`).then(()=>{
                    this.$router.push('/signin')
                })
            }
        },
    },
    mounted(){
        this.$EventBus.$emit('HideNav')
    },
    destroyed(){
        this.$EventBus.$emit('ShowNav')
    }
}
</script>

<style lang="scss" scoped>
    .find_email{
        min-height: 500px;
    }

    .form-control{
        font-weight: bold;

        &::placeholder{
            font-weight: normal;
        }
    }
</style>