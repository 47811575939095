<template>
  <div class="signin position-relative">
    <div class="d-flex">
      <router-link to="/home" class="ms-auto"
        ><i class="fal fa-home-alt p-3 text-gray-relative-500"></i
      ></router-link>
    </div>

    <div class="px-3 position-absolute top-50 start-50 translate-middle w-100">
      <div class="mx-auto">
        <div class="my-5">
          <img
            src="https://golfandyacht.s3.ap-northeast-2.amazonaws.com/1691532559818logo500x500.png"
            width="75%"
            class="rounded mx-auto d-block"
          />
        </div>
      </div>
      <p class="p-3">가맹점 지원</p>
      <div class="mx-auto my-auto p-3 pt-4 bg-body rounded-3">
        <div>
          골프앤요트 LOGIN
          <div class="pt-3 px-0">
            <div role="form">
              <div class="mb-1">
                <input
                  type="text"
                  class="form-control bg-body py-3"
                  v-model="id"
                  placeholder="아이디"
                />
              </div>
              <div class="mb-3">
                <input
                  type="password"
                  class="form-control bg-body py-3"
                  v-model="password"
                  placeholder="비밀번호"
                  autocomplete="none"
                  @keydown.enter="Login"
                  ref="pwRef"
                />
              </div>
              <div class="text-center mt-3">
                <button
                  type="button"
                  class="btn btn-main thick w-100 btn-lg text-white shadow-sm"
                  @click="Login()"
                >
                  <small>로그인</small>
                </button>
              </div>
              <div class="mb-2 mt-4">
                <div class="text-center small">
                  <span class="ms-2">
                    <router-link
                      to="/find-pw"
                      class="text-gray-relative-500 ps-3 cusor-pointer"
                    >
                      비밀번호를 잊으셨나요?
                    </router-link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-between-center mt-3 px-3">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            id="save_id"
            v-model="save_id"
            @change="handleSaveId"
          />
          <label class="form-check-label" for="save_id"> 아이디 저장 </label>
        </div>

        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            id="auto_login"
            v-model="auto_login"
            @change="handleAutoLogin"
          />
          <label class="form-check-label" for="auto_login"> 자동 로그인 </label>
        </div>
      </div>

      <div class="px-4 py-3 text-gray-relative-500 fs-px-13 text-center">
        <!-- <router-link to="/find-id" class="me-5">아이디 찾기</router-link> -->
        <!-- <router-link to="/find-pw">비밀번호 찾기</router-link> -->
      </div>
    </div>
    <Loading v-if="loading" />
  </div>
</template>

<script>
import Loading from '@/components/common/Loading.vue';
import { mapState, mapMutations } from 'vuex';
const CryptoJS = require('crypto-js');

export default {
  components: {
    Loading,
  },
  data() {
    return {
      id: '',
      password: '',
      save_id: false,
      auto_login: false,
      loading: false,
      push_token: '',
    };
  },
  computed: {
    ...mapState(['is_save_id', 'is_auto_login', 'token']),
  },
  methods: {
    ...mapMutations(['SetAutoLogin', 'SetSaveId']),
    Login() {
      const pwRef = this.$refs.pwRef;
      pwRef.blur();

      const id = this.id;
      const password = this.password;
      const push_token = this.push_token;
      const body = { id, password, push_token };

      const req = CryptoJS.AES.encrypt(
        JSON.stringify(body),
        process.env.VUE_APP_SECRET_TOKEN
      ).toString();

      this.loading = true;
      this.$http.post('/shop/login/Login', { req }).then((res) => {
        if (res.status == 200) {
          this.loading = false;
          if (res.data.code == '200') {
            const e_body = res.data.body;
            const bytes = CryptoJS.AES.decrypt(
              e_body,
              process.env.VUE_APP_SECRET_TOKEN
            );
            const d_res = bytes.toString(CryptoJS.enc.Utf8);
            const body = JSON.parse(d_res);

            const login = true;
            const name = body.name;
            const token = body.token;
            const shop_name = body.shop_name;
            const auth = body.auth;

            const mem_info = { login, name, token, shop_name, auth };

            this.$cookies.set(process.env.VUE_APP_ID_KEY, id);
            this.$store.dispatch('SETLOGIN', { mem_info }).then(() => {
              // this.$alert(`<i class="fal fa-check-circle fa-2x mb-2 text-success"></i><p class="small mt-1">로그인 완료하였습니다.</p>`).then(()=>{
              //     window.location.href="/home";
              // })
              this.$router.push('/home');
            });
          } else if (res.data.code == '300') {
            this.$alert(
              '<i class="fal fa-exclamation-circle fa-2x mb-2 text-danger"></i><p class="small mt-1">사용자 이메일 계정<br>정보를 확인해주세요.</p>'
            );
          } else if (res.data.code == '100') {
            this.$alert(
              '<i class="fal fa-exclamation-circle fa-2x mb-2 text-danger"></i><p class="small mt-1">사용자 이메일 계정이<br>존재하지 않습니다.</p>'
            );
          }
        }
      });
    },
    GetLoginCookies() {
      if (this.save_id) {
        const login_id = this.$cookies.get(process.env.VUE_APP_ID_KEY);
        this.id = login_id;
      }
    },
    GetCheckboxStore() {
      this.save_id = this.is_save_id;
      this.auto_login = this.is_auto_login;
    },
    handleSaveId() {
      this.SetSaveId(this.save_id);

      if (!this.save_id) {
        this.auto_login = false;
        this.SetAutoLogin(this.auto_login);
      }
    },
    handleAutoLogin() {
      this.SetAutoLogin(this.auto_login);

      if (!this.auto_login) {
        this.save_id = false;
        this.SetSaveId(this.save_id);
      } else {
        this.save_id = true;
        this.SetSaveId(this.save_id);
      }
    },
    CheckAutoLogin() {
      if (this.is_auto_login && this.token) {
        this.$router.push('/home');
      } else {
        this.$store.dispatch('SETLOGOUT');
      }
    },
    GetToken() {
      try {
        if (/Android/i.test(navigator.userAgent)) {
          HybridApp.saveToken();
        }
      } catch (e) {}
    },
    getAndroidToken(token) {
      this.push_token = token;
    },
  },
  mounted() {
    window.login = this;
    this.GetCheckboxStore();
    this.GetLoginCookies();
    this.GetToken();
    this.$EventBus.$emit('HideNav');
    this.CheckAutoLogin();
  },
  destroyed() {
    this.$EventBus.$emit('ShowNav');
  },
};
</script>

<style lang="scss" scoped>
.signin {
  background-color: #eee;
  height: 100vh;
  min-height: 500px;
  max-width: 400px;
  margin: 0 auto;
}

.form-control {
  font-weight: bold;

  &::placeholder {
    font-weight: normal;
  }
}
</style>
